import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
let myDate = new Date();
const store = new Vuex.Store({
    namespaced: true,
    state:{
        tableTabs:[],
        daydate:myDate.getFullYear() +'-'+ (myDate.getMonth()+1) +'-'+ myDate.getDate(),
        m_id:0

    },
    mutations:{
        setProductCode(state, data) {
            state.m_id= data;
        },
    }
});

export default store;
