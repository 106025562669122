import Vue from 'vue'
import Router from 'vue-router'
// import login from './components/login/login.vue'
// import home from './views/home/home.vue'
// import welcome from './components/welcome.vue'
// //部门管理
// import department from './components/SystemManagement/department.vue'
// //用户管理
// import users from './components/SystemManagement/users.vue'
// //角色管理
// import role from './components/SystemManagement/role.vue'
// //菜单管理
// import menu from './components/SystemManagement/menu.vue'
// //固定资产
// import fixedassets from './components/SystemManagement/fixedassets.vue'
//
// // import rights from './components/power/rights.vue'
//
// //
// //商品分类
// import cate from './components/goods/cate.vue'
// //商品列表
// import goods from './components/goods/goods.vue'
// //保质期
// import dategoods from './components/goods/dategoods.vue'
// //计量单位
// import goodsunit from './components/goods/goodsunit.vue'
// //qualityleve 质量等级
// import qualityleve from './components/goods/qualityleve.vue'
// //添加商品
// import addgoods from './components/goods/addgoods.vue'
// //修改商品
// import editgoods from './components/goods/editgoods.vue'
// //添加规格商品
// import addskugoods from './components/goods/addskugoods.vue'
// //上传图片 识别图片
// // import upstockimg from './components/goods/upstockimg.vue'
// //查看商品导入记录
// import goodslog from './components/goods/goodslog.vue'
// //添加商品属性
// import params from './components/goods/params.vue'
//
// //仓库信息
// import warehouse from './components/BasicInformation/warehouse.vue'
// //供应商
// import supplier from './components/BasicInformation/supplier.vue'
// //客户管理
// import customer from './components/BasicInformation/customer.vue'
// //添加客户
// import addcustomer from './components/BasicInformation/addcustomer.vue'
// //修改客户
// import editcustomer from './components/BasicInformation/editcustomer.vue'
// //客户等级
// import customerlevel from './components/BasicInformation/customerlevel.vue'
// //操作日志
// import logoperation from './components/LogManagement/logoperation.vue'
// //登录日志
// import loglogin from './components/LogManagement/loglogin.vue'
//
// //purchase 库存管理
// import purchase from './components/stock/purchase.vue'
// //purchase 添加库存管理
// import addpurchase from './components/stock/addpurchase.vue'
// //purchase 修改库存管理
// import editpurchase from './components/stock/editpurchase.vue'
// //purchase 入库库存管理
// import PurchaseWarehousing from './components/stock/PurchaseWarehousing.vue'
// //purchase 库存管理详情
// import purchaseDetails from './components/stock/purchaseDetails.vue'
// //purchase 添加付款记录
// import purchasePay from './components/stock/purchasePay.vue'
// //入库单
// import warehouseenter from './components/stock/warehouseenter.vue'
// //添加入库单
// import addwarehouseenter from './components/stock/addwarehouseenter.vue'
// //入库单详情
// import warehouseenterDetails from './components/stock/warehouseenterDetails.vue'
// //出库单
// import warehouseout from './components/stock/warehouseout.vue'
// //出库单
// import warehouseoutDetails from './components/stock/warehouseoutDetails.vue'
// //添加出库单
// import addwarehouseout from './components/stock/addwarehouseout.vue'
// //库存调拨
// import warehouseallocation from './components/stock/warehouseallocation.vue'
// //新建库存调拨
// import addwarehouseallocation from './components/stock/addwarehouseallocation.vue'
// //库存调拨详情
// import warehouseallocationDetails from './components/stock/warehouseallocationDetails.vue'
// //excel
// import excel from './components/excel/upload-excel.vue'
// //图片裁剪
//
// //销售管理 商品销售
// import goodssale from './components/sale/goodssale.vue'
// import addgoodssale from './components/sale/addgoodssale.vue'
// import goodssaledetails from './components/sale/goodssaledetails.vue'
// import addgoodssalepay from './components/sale/addgoodssalepay.vue'
// import addgoodssaleout from './components/sale/addgoodssaleout.vue'
//
// //报表管理
// import SalesStatistics from './components/reportForm/SalesStatistics.vue'
// import stockcensus from './components/reportForm/stockcensus.vue'
// import purchasecensus from './components/reportForm/purchasecensus.vue'
// //测试
// import ceshi from './components/excel/ceshi.vue'
// import sku from './components/excel/sku.vue'

Vue.use(Router)


const router = new Router({
    routes:[
        {
            path:'/login',
            name: 'login',
            meta: {
                title: "登录",
                keepAlive: false,
                footer: true,
                backgroundColor: "#fff"
            },
            // redirect:'/login',
            component:resolve =>require(['@/components/login/login'],resolve)
        },
        {
            path:'/',
            name: 'home',
            meta: {
                title: "首页",
                keepAlive: false,
                footer: true,
                backgroundColor: "#fff"
            },
            redirect: '/home',
            component:resolve =>require(['./views/home/home.vue'],resolve),
            children:[{
                path:'/home',
                name: 'welcome',
                meta: {
                    title: "首页",
                    keepAlive: false,
                    footer: true,
                    backgroundColor: "#fff"
                },
                component:resolve =>require(['@/components/welcome'],resolve),
            },{
                path:'/users',
                name: 'users',
                meta: {
                    title: "用户管理",
                    keepAlive: false,
                    footer: true,
                    backgroundColor: "#fff"
                },
                component:resolve =>require(['@/components/SystemManagement/users'],resolve),
            },{
                path:'/department',
                name: 'department',
                component:resolve =>require(['@/components/SystemManagement/department'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/role',
                name: 'role',
                component:resolve =>require(['@/components/SystemManagement/role'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/menu',
                name: 'menu',
                component:resolve =>require(['@/components/SystemManagement/menu'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/fixedassets',
                name: 'fixedassets',
                component:resolve =>require(['@/components/SystemManagement/fixedassets'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/salecensus',
                name: 'salecensus',
                component:resolve =>require(['@/components/reportForm/SalesStatistics'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/stockcensus',
                name: 'stockcensus',
                component:resolve =>require(['@/components/reportForm/stockcensus'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/purchasecensus',
                name: 'purchasecensus',
                component:resolve =>require(['@/components/reportForm/purchasecensus'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/categories',
                name: 'categories',
                component:resolve =>require(['@/components/goods/cate'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goods',
                name: 'goods',
                component:resolve =>require(['@/components/goods/goods'],resolve),
                meta: {
                    keepAlive: true
                }
            },{
                path:'/goodslist',
                name: 'goodslist',
                component:resolve =>require(['@/components/goods/goodslist'],resolve),
                meta:{
                    keepAlive: false
                }

            },{
                path:'/goodslist/goodslistdetails',
                name: 'goodslistdetails',
                component:resolve =>require(['@/components/goods/goodslistdetails'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/params',
                name: 'params',
                component:resolve =>require(['@/components/goods/params'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodsunit',
                name: 'goodsunit',
                component:resolve =>require(['@/components/goods/goodsunit'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/qualityleve',
                name: 'qualityleve',
                component:resolve =>require(['@/components/goods/qualityleve'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goods/dategoods',
                name: 'dategoods',
                component:resolve =>require(['@/components/goods/dategoods'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goods/addgoods',
                name: 'addgoods',
                component:resolve =>require(['@/components/goods/addgoods'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goods/editgoods',
                name: 'editgoods',
                component:resolve =>require(['@/components/goods/editgoods'],resolve),
                meta:{
                    keepAlive: false
                }

            },{
                path:'/goods/addskugoods',
                name: 'addskugoods',
                component:resolve =>require(['@/components/goods/addskugoods'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goods/goodslog',
                name: 'goodslog',
                component:resolve =>require(['@/components/goods/goodslog'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/warehouse',
                name: 'warehouse',
                component:resolve =>require(['@/components/BasicInformation/warehouse'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/supplier',
                name: 'supplier',
                component:resolve =>require(['@/components/BasicInformation/supplier'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/customer',
                name: 'customer',
                component:resolve =>require(['@/components/BasicInformation/customer'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/customer/addcustomer',
                name: 'addcustomer',
                component:resolve =>require(['@/components/BasicInformation/addcustomer'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/customer/editcustomer',
                name: 'editcustomer',
                component:resolve =>require(['@/components/BasicInformation/editcustomer'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/customerlevel',
                name: 'customerlevel',
                component:resolve =>require(['@/components/BasicInformation/customerlevel'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/logoperation',
                name: 'logoperation',
                component:resolve =>require(['@/components/LogManagement/logoperation'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/loglogin',
                name: 'loglogin',
                component:resolve =>require(['@/components/LogManagement/loglogin'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/purchase',
                name: 'purchase',
                component:resolve =>require(['@/components/stock/purchase'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/addpurchase',
                name: 'addpurchase',
                component:resolve =>require(['@/components/stock/addpurchase'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/editpurchase',
                name: 'editpurchase',
                component:resolve =>require(['@/components/stock/editpurchase'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/PurchaseWarehousing',
                name: 'PurchaseWarehousing',
                component:resolve =>require(['@/components/stock/PurchaseWarehousing'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/purchaseDetails',
                name: 'purchaseDetails',
                component:resolve =>require(['@/components/stock/purchaseDetails'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/purchasePay',
                name: 'purchasePay',
                component:resolve =>require(['@/components/stock/purchasePay'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/warehouseenter',
                name: 'warehouseenter',
                component:resolve =>require(['@/components/stock/warehouseenter'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/addwarehouseenter',
                name: 'addwarehouseenter',
                component:resolve =>require(['@/components/stock/addwarehouseenter'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/warehouseenterDetails',
                name: 'warehouseenterDetails',
                component:resolve =>require(['@/components/stock/warehouseenterDetails'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/warehouseout',
                name: 'warehouseout',
                component:resolve =>require(['@/components/stock/warehouseout'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/warehouseoutDetails',
                name: 'warehouseoutDetails',
                component:resolve =>require(['@/components/stock/warehouseoutDetails'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/addwarehouseout',
                name: 'addwarehouseout',
                component:resolve =>require(['@/components/stock/addwarehouseout'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/warehouseallocation',
                name: 'warehouseallocation',
                component:resolve =>require(['@/components/stock/warehouseallocation'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/warehouseallocation/addwarehouseallocation',
                name: 'addwarehouseallocation',
                component:resolve =>require(['@/components/stock/addwarehouseallocation'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/warehouseallocationDetails',
                name: 'warehouseallocationDetails',
                component:resolve =>require(['@/components/stock/warehouseallocationDetails'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/excel',
                name: 'excel',
                component:resolve =>require(['@/components/excel/sku'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/excels',
                name: 'excels',
                component:resolve =>require(['@/components/excel/upload-excel'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodssale',
                name: 'goodssale',
                component:resolve =>require(['@/components/sale/goodssale'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodssale/addgoodssale',
                name: 'addgoodssale',
                component:resolve =>require(['@/components/sale/addgoodssale'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodssale/goodssaledetails',
                name: 'goodssaledetails',
                component:resolve =>require(['@/components/sale/goodssaledetails'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodssale/addgoodssalepay',
                name: 'addgoodssalepay',
                component:resolve =>require(['@/components/sale/addgoodssalepay'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodssale/addgoodssaleout',
                name: 'addgoodssaleout',
                component:resolve =>require(['@/components/sale/addgoodssaleout'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/purchasereturn',
                name: 'purchasereturn',
                component:resolve =>require(['@/components/returnpurchase/returnpurchase'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/purchasereturn/addpurchasereturn',
                name: 'addpurchasereturn',
                component:resolve =>require(['@/components/returnpurchase/addreturnpurchase'],resolve),
                meta:{
                    keepAlive: false
                }
            }, {
                path:'/purchasereturn/purchasereturnDetails',
                name: 'returnpurchaseDetails',
                component:resolve =>require(['@/components/returnpurchase/returnpurchaseDetails'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/purchasereturn/editpurchasereturn',
                name: 'editreturnpurchase',
                component:resolve =>require(['@/components/returnpurchase/editreturnpurchase'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/purchasereturn/purchasereturnPay',
                name: 'returnpurchasePay',
                component:resolve =>require(['@/components/returnpurchase/returnpurchasePay'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/purchasereturn/returnpurchasewarehouseout',
                name: 'purchasereturnwarehouseenter',
                component:resolve =>require(['@/components/returnpurchase/returnpurchasewarehouseout'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/paymentcensus',
                name: 'paymentcensus',
                component:resolve =>require(['@/components/reportForm/paymentcensus'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/collectioncensus',
                name: 'collectioncensus',
                component:resolve =>require(['@/components/reportForm/collectioncensus'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodssalereturn',
                name: 'goodssalereturn',
                component:resolve =>require(['@/components/goodssalereturn/goodssalereturn'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodssalereturn/addgoodssalereturn',
                name: 'addgoodssalereturn',
                component:resolve =>require(['@/components/goodssalereturn/addgoodssalereturn'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodssalereturn/goodssalereturndetails',
                name: 'goodssalereturndetails',
                component:resolve =>require(['@/components/goodssalereturn/goodssalereturndetails'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodssalereturn/addgoodssalereturnpay',
                name: 'addgoodssalereturnpay',
                component:resolve =>require(['@/components/goodssalereturn/addgoodssalereturnpay'],resolve),
                meta:{
                    keepAlive: false
                }
            },{
                path:'/goodssalereturn/goodssalereturnwarehouseenter',
                name: 'goodssalereturnwarehouseenter',
                component:resolve =>require(['@/components/goodssalereturn/goodssalereturnwarehouseenter'],resolve),
                meta:{
                    keepAlive: false
                }
            },

            ]
        }
    ]
})
router.beforeEach((to, form, next) => {
    // let title=to.meta.title
    // document.title=title
    //to 将要访问的路径
    //form  代表从哪个路径跳转而来
    // next() 放行  next('/login') 强制跳转
    if (to.path === '/login') return next()
    //获取 token
    const tokenStr = window.sessionStorage.getItem('token')
    if (!tokenStr) return next('/login')

    next()
})

// const router = new Router({
//     routes: [
//         {path: '/', redirect: '/login'},
//         {path: '/login', component: login},
//         {
//             path: '/home',
//             component: home,
//             redirect: '/welcome',
//             children: [
//                 {path: '/welcome', component: welcome},
//                 {path: '/users', component: users},
//                 {path: '/department', component: department},
//                 {path: '/role', component: role},
//                 {path: '/menu', component: menu},
//                 {path: '/fixedassets', component: fixedassets},
//                 //报表
//                 {path: '/salecensus', component: SalesStatistics},
//                 {path: '/stockcensus', component: stockcensus},
//                 {path: '/purchasecensus', component: purchasecensus},
//                 // {path: '/roles', component: roles},
//                 {path: '/categories', component: cate},
//                 {path: '/goods', component: goods},
//                 {path: '/goodsunit', component: goodsunit},
//                 {path: '/qualityleve', component: qualityleve},
//                 {path: '/goods/dategoods', component:dategoods},
//                 {path: '/goods/addgoods', component:addgoods},
//                 {path: '/goods/editgoods', component:editgoods},
//                 {path: '/goods/addskugoods', component:addskugoods},
//                 // {path: '/goods/upstockimg', component:upstockimg},
//                 {path: '/goods/goodslog', component:goodslog},
//                 {path: '/params', component:params},
//                 {path: '/warehouse', component:warehouse},
//                 {path: '/supplier', component:supplier},
//                 {path: '/customer', component:customer},
//                 {path: '/customer/addcustomer', component:addcustomer},
//                 {path: '/customer/editcustomer', component:editcustomer},
//                 {path: '/customerlevel', component:customerlevel},
//                 {path: '/logoperation', component:logoperation},
//                 {path: '/loglogin', component:loglogin},
//                 {path: '/purchase', component:purchase},
//                 {path: '/addpurchase', component:addpurchase},
//                 {path: '/editpurchase', component:editpurchase},
//                 {path: '/PurchaseWarehousing', component:PurchaseWarehousing},
//                 {path: '/purchaseDetails', component:purchaseDetails},
//                 {path: '/purchasePay', component:purchasePay},
//                 {path: '/warehouseenter', component:warehouseenter},
//                 {path: '/addwarehouseenter', component:addwarehouseenter},
//                 {path: '/warehouseenterDetails', component:warehouseenterDetails},
//                 {path: '/warehouseout', component:warehouseout},
//                 {path: '/warehouseoutDetails', component:warehouseoutDetails},
//                 {path: '/addwarehouseout', component:addwarehouseout},
//                 {path: '/warehouseallocation', component:warehouseallocation},
//                 {path: '/warehouseallocation/addwarehouseallocation', component:addwarehouseallocation},
//                 {path: '/warehouseallocationDetails', component:warehouseallocationDetails},
//                 {path: '/excel', component:sku},
//                 {path: '/excels', component:excel},
//                 {path: '/goodssale', component:goodssale},
//                 {path: '/goodssale/addgoodssale', component:addgoodssale},
//                 {path: '/goodssale/goodssaledetails', component:goodssaledetails},
//                 {path: '/goodssale/addgoodssalepay', component:addgoodssalepay},
//                 {path: '/goodssale/addgoodssaleout', component:addgoodssaleout},
//                 {path: '/ceshi', component:ceshi},
//                 // {path: '/sku', component:sku},
//             ]
//         }
//     ]
// })


export default router
