  <template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import store from './store/store'//引入store
export default {
  name:'app',
  store,
}
</script>

<style>
.el-table--enable-row-hover .el-table__body tr:hover>td{    background-color: #cee4fd!important}
.el-table__body tr.current-row>td {
  background-color: #b3d7ff!important;
}
</style>
