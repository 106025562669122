import Vue from 'vue'
import App from './App.vue'
import { store } from './store/store'
// import Vuex from './vuex/store'
import ElementUI from 'element-ui'
import {Message,MessageBox} from 'element-ui'
import router from "./router"
import 'element-ui/lib/theme-chalk/index.css'
//导入全局样式
import './assets/css/global.css'
import axios from 'axios'
import treeTable from 'vue-table-with-tree-grid'
// import dragTreeTable from 'drag-tree-table'
//导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
//md5
import md5 from 'js-md5'
// 打印
import Print from 'vue-print-nb'
import VueCropper from 'vue-cropper'
// import Vuex from 'vuex'
//图表
import echarts from 'echarts'
// require styles 富文本编辑器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// //配置请求的根路径
// axios.defaults.baseURL='http://10.0.0.238:83/adminapi'

axios.defaults.baseURL=process.env.VUE_APP_BASE_API
// axios.defaults.baseURL='https://sale.aixspace.net/index.php/adminapi'


axios.interceptors.request.use(config=>{
  // console.log(config)
  config.headers.Authorization = 'Bearer ' + window.sessionStorage.getItem('token')
//   //在最后必须return config
  return config
})
/**
 * 响应拦截器
 */
axios.interceptors.response.use(response => {
  if (response.status === 200) {
    if(response.data.code===410000){
      // console.log(response.data)
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        }
      });
    }
    else if(response.data.code===410001){
      // console.log(response.data)
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        }
      });
    }
    else if(response.data.code===410002){
      // console.log(response.data)
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        }
      });
    }
    return Promise.resolve(response);
  }
  else {
    return Promise.reject(response);
  }
  // return response.data;
}, error => {
  // console.log(123)
});
Vue.prototype.$http = axios
Vue.prototype.$axios = axios
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$md5 = md5
Vue.prototype.$echarts = echarts
Vue.prototype.NumberMul = function (arg1,arg2){
  var m = 0;
  if (arg1){
    if (arg2){
      var s1 = arg1.toString();
      var s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}
      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    }
  }
}
Vue.prototype.money = function (value) {
  // console.log(value, typeof value, value.replace(/[^\.\d]/g, ""));
  let str = value;
  let len1 = str.substr(0, 1);
  let len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    str = str.substr(1, 1);
  }
  //第一位不能是.
  if (len1 == ".") {
    str = "";
  }
  if (len1 == "+") {
    str = "";
  }
  if (len1 == "-") {
    str = "";
  }

  //限制只能输入一个小数点
  if (str.indexOf(".") != -1) {
    let str_ = str.substr(str.indexOf(".") + 1);
    if (str_.indexOf(".") != -1) {
      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
    }
  }
  //正则替换
  // str = str.replace(/[^\d^\.]+/g, ""); // 保留数字和小数点
  // str = str.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, "$1"); // 小数点后只能输 2 位
  str = str.replace(/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,"$1")
  return str;
}




//将富文本编辑器 注册为全局可用的组件
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(ElementUI)
Vue.use(VueCropper)
Vue.use(Print)
Vue.component('tree-table',treeTable)
// Vue.use(store)

// Vue.component('drag-tree-table',dragTreeTable)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
